import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './MapSurveyInfoWindow';

class MapSurveyMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      selectedSurveyInfo: null,
    };
  }


  handleClick = (event) => {
    console.log("MapSurveyMarker:handleClick:",this.props.surveyInfo);
    //event.stopPropagation(); //Otherwise map rectangle select 
    this.props.handleMarkerClick(this.props.surveyInfo);
  }


  render() {
    let backgroundColor = 'lightgrey' ;
    let textColor = 'black' ;
    let border = '1px solid black' ;
    let radius=20;

    let surveyState = this.props.surveyInfo.surveyState;
    let holeState   = this.props.surveyInfo.holeState;

    if      (this.props.layerOptions === '2') {surveyState=this.props.surveyInfo.surveyDepthState;    holeState=this.props.surveyInfo.depthHoleState; }
    else if (this.props.layerOptions === '3') {surveyState=this.props.surveyInfo.surveyLoadedState;   holeState=this.props.surveyInfo.loadedHoleState;}
    else if (this.props.layerOptions === '4') {surveyState=this.props.surveyInfo.surveyStemmingState; holeState=this.props.surveyInfo.stemmingHoleState;}

    else if (this.props.layerOptions === '5') {surveyState=this.props.surveyInfo.postDrillState; holeState=this.props.surveyInfo.postDrillHoleState;}
    else if (this.props.layerOptions === '6') {surveyState=this.props.surveyInfo.preLoadState;   holeState=this.props.surveyInfo.preLoadHoleState;}
    else if (this.props.layerOptions === '7') {surveyState=this.props.surveyInfo.postLoadState;  holeState=this.props.surveyInfo.postLoadHoleState;}
    else if (this.props.layerOptions === '8') {surveyState=this.props.surveyInfo.preStemState;   holeState=this.props.surveyInfo.preStemHoleState;}

    if (surveyState==0) {textColor = 'black'; backgroundColor = 'lightgrey'; }
    else if (surveyState==1) {textColor = 'white'; backgroundColor = 'green'; }
    else if (surveyState==2) {textColor = 'black'; backgroundColor = 'peru'; border='3px solid crimson'; radius=25;}
    else if (surveyState==3) {textColor = 'black'; backgroundColor = 'peru'; border='3px solid blue'; radius=25;} //'rgb(255, 99, 71)';
    else if (surveyState==4) {textColor = 'white'; backgroundColor = 'blue'; }
    else if (surveyState==5) {textColor = 'gold'; backgroundColor = 'green'; }
    else if (surveyState==6) {textColor = 'black'; backgroundColor = 'gold'; border='3px solid crimson'; radius=25;}
    else if (surveyState==7) {textColor = 'black'; backgroundColor = 'gold'; border='3px solid blue'; radius=25;}

    if ((holeState & 0x02) > 0) { textColor = 'white'; backgroundColor = 'black'; border='3px solid fuchsia'; radius=25;} //Colapsed
    if ((holeState & 0x20) > 0) { textColor = 'white'; backgroundColor = 'black'; border='3px solid crimson'; radius=25;} //OverBurden 
    if ((holeState & 0x40) > 0) { textColor = 'white'; backgroundColor = 'black'; border='3px solid aqua'; radius=25;} //Cracked 

    if (this.props.surveyInfo.filtered===1) {textColor = 'black'; backgroundColor = 'lightgrey'; border ='1px solid black'; radius=20;}

    const markerStyle = {
      border: (this.props.$hover||this.props.selected)?'3px solid fuchsia':border, 
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: (this.props.$hover||this.props.selected)?radius+6:radius, 
      width:  (this.props.$hover||this.props.selected)?radius+6:radius,
      backgroundColor: (this.props.$hover||this.props.selected)?'fuchsia':backgroundColor,
      cursor: 'pointer',
      zIndex: 3,
      textAlign: 'center',
      lineHeight: '20px',
    };

    const textStyle = {
      color: (this.props.$hover||this.props.selected)?'white':textColor,
      fontSize: (this.props.$hover||this.props.selected) ? 12:11,
      fontWeight:( this.props.$hover||this.props.selected) ? 'bold':'normal',
    };

    return (
      <div>
        <div style={markerStyle}  onClick={this.handleClick} > <span style={textStyle}>{this.props.surveyInfo.holeNo}</span></div>
        {((this.props.$hover && this.props.markerClickInfo === null) ||  (this.props.showInfoWindow)) && //hover but not clicked OR Clicked
          <InfoWindow
            hole              = {this.props.surveyInfo}
            userDetails       = {this.props.userDetails}
            refreshData       = {this.props.refreshData}
            handleMarkerClear = {this.props.handleMarkerClear}
            handleClick       = {this.handleClick}
            loadAddEnabled    = {true}
            markerClickInfo   = {this.props.markerClickInfo} />}
      </div>
    );
  }
}

export default MapSurveyMarker;

