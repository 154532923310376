import React, { Component } from "react";
import { RangeDatePicker } from '@y0c/react-datepicker';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
import moment from "moment"
import Select from 'react-select';
import Button from   'react-bootstrap/Button';
import ButtonGroup from   'react-bootstrap/ButtonGroup';
import ToggleButton from   'react-bootstrap/ToggleButton';
import DashBoard from './DashBoard';
import TableHoleInfo from './CommonTableHoleInfo';
import TablePatternList from './Report4TablePatternList';
import TableDailySummary from './Report4TableDailySummary';
import TableAccessorySummary from './Report4TableAccessorySummary';
import TableMedia from './Report4TableMedia';
import StyledDropzone from './StyledDropzone';
import Chart1 from './Report4Chart.1';
import Map from './CommonMap';
import ProgressBar from 'react-bootstrap/ProgressBar'
import {Element, scroller} from 'react-scroll';
import dayjs from "dayjs"
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import AuthoriseObj from './Authorise.js';
import PatternCanvas from './PatternCanvas.js';
import { withTranslation, useTranslation } from 'react-i18next';
import i18n from '../translations/i18n';
 
class Report4 extends Component {

    constructor(props) {
        super(props);

        let now       = dayjs();
        let endDate   = now;
        let startDate = now.subtract('3', 'month');

        this.state = {
            drillLevel : 0,                          
            startDate  : startDate.format('YYYY-MM-DD'),
            endDate    : endDate.format('YYYY-MM-DD'),
            selectedSiteId: null,
            selectedPatternId: null,
            date: null,
            error:null,
            selectedPattern:null,
            selectedAccessoryId:null,
            selectedProductId:null,
            selectedDeckNo:1,
            selectedPatternApprovedState:null,
       	    patternList : null,
	          patternState : null,
	          patternUrl : null,
            patternDaySummary : null,
            patternAccessorySummary : null,
            patternSummary : null,
            sites : [],      
            patterns : [],      
            patternVehicles : null,
            patternDates : null,
            accessoryIds : null,
            productIds : null,
            holeInfos : null,
            loadInfos : null,
            designInfos : null,
            surveyInfos : null,
            accessoryInfos : null,
            productInfos : null,
            accessoryPatternDates : null,
            filteredHoleInfos : null,
            filteredSurveyInfos : null,
            holeInfoVersion : 0,
            selectedHoleId : 0,
            drivers: null,
            blasters: null,
	          productTypes: null,
            mediaFiles : null,
            storageProgress : 0,
	          searchEnabled : true,
            layerLoadedVisible : false,
            layerAccessoriesVisible : false,
            layerSurveyVisible : false,
            layerSurveyOptions : '1',
            layerSurveyShowGpsPos : false,
            layerAccessoriesOptions : '1',
            layerHoleStateVisible : false,
            layerHoleStateOptions : '0',
            layerDesignVisible : false,
            layerHeatMapVisible : false,
            layerOverFillVisible : false,
            layerOverFillOptions : '1',
            layerLoadProgressVisible : false,
            layerVehicleVisible : false,
            layerProductVisible : false,
            patternCanvasVisible : false
        };

        this.handleChangeSites = this.handleChangeSites.bind(this);    
        this.handleChangePatterns = this.handleChangePatterns.bind(this);    

        //this.mapRef = React.createRef();
    }


    componentDidMount() {       
      this.getPatternList (this.props.userDetails.userId, this.props.userDetails.companyId);
      this.getSites();
      this.getAccessoryIds();
      this.getProductIds();

      this.setState({searchEnabled:true});

      if (this.props.searchEnabled===false){
        this.setState({searchEnabled:false});
      }


      if (this.props.location) { //There are some URL variables, we only care for UUID for the direct URL link to patterns
        const value=queryString.parse(this.props.location.search);
        const uuid=value.uuid;
        console.log('UUID=',uuid)
  
        if (uuid) {
          fetch(process.env.REACT_APP_HOST_URL+'report4Uuid2PatternDS.php',
          {
            method: 'POST',
            body: JSON.stringify(
            {
              userId    : this.props.userDetails.userId,
              companyId : this.props.userDetails.companyId,
              uuid      : uuid
            })
          })
          .then(response => response.json())
          .then(data => {this.setState({ selectedSiteId:data.siteId, selectedPatternId:data.patternId }); 
                         this.getPatternDaySummary       (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         this.getPatternAccessorySummary (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         this.getHoleData                (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         this.getPatternVehicles         (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         this.getPatternDates            (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         this.getPatternState            (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         this.getDesignData              (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         this.getSurveyData              (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         this.getAccessoryData           (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId, 
                                                          null, this.state.selectedDeckNo);
                         this.getProductData             (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId, 
                                                          null, this.state.selectedDeckNo);
                         this.getAccessoryPatternDates   (this.props.userDetails.userId, this.props.userDetails.companyId, data.siteId, data.patternId);
                         })
          .catch(error => this.setState({ error }));
        }
      }
       //if(this.mapRef.current!=null) {
       //  this.mapRef.current.focus();
       //}
    }


    componentDidUpdate(prevProps){
       console.log ("Report4:componentDidUpdate", prevProps,this.mapRef);
       //if(this.mapRef.current!=null) {
       //  this.mapRef.current.focus();
       //}
    }


    getSites = () =>{
      fetch(process.env.REACT_APP_HOST_URL+'reportSitesDS.php',
      {
        method: 'POST',                
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId
        })
      })
      .then(response => response.json())
      .then(data => this.setState({ sites:data}))
      .catch(error => this.setState({ error }));
    }

    getPatterns = (selectedSiteId) =>{
      fetch(process.env.REACT_APP_HOST_URL+'reportPatternsDS.php',
        {
          method: 'POST',
          body: JSON.stringify(
          {
            userId    : this.props.userDetails.userId,
            companyId : this.props.userDetails.companyId,
            siteId    : selectedSiteId,
            startDate : this.state.startDate,
            endDate   : this.state.endDate
          })
        })
        .then(response => response.json())
        .then(data => this.setState({ patterns:data }))
        .catch(error => this.setState({ error }));
    }


    getProducts = () =>{
      fetch(process.env.REACT_APP_HOST_URL+'commonProductTypesV2DS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId,
          typeId    : 1,
        })
      })
      .then(response => response.json())
      .then(data => this.setState({ products:data }))
      .catch(error => this.setState({ products:[] }));
    }

    onChange = (startDate,endDate) => {       
      console.log(moment(startDate).format('YYYY-MM-DD') + '->'+moment(endDate).format('YYYY-MM-DD'));
      var startDateMySql = moment(startDate).format('YYYY-MM-DD');
      var endDateMySql = moment(endDate).format('YYYY-MM-DD');
      this.setState({startDate:startDateMySql, endDate:endDateMySql, drillLevel:0 });
    }

    handleChangeSites = (selectedSite) => {     
      var selectedSiteId   = selectedSite.value ;           
      this.setState({ selectedSiteId, drillLevel:0, selectedPattern:null });
      console.log(`Report4:handleChangeSites:`, selectedSiteId );
      this.getPatterns(selectedSiteId);
    }

    handleChangePatterns = (selectedPattern) => {     
      var selectedPatternId = selectedPattern.value ;  
      this.setState({ selectedPattern, selectedPatternId, drillLevel:0 });
      console.log(`Report4:handleChangePatterns:`, selectedPatternId );        
    }

    handleAccessorySelect = (selectedAccessoryId) => {
      console.log(`Report4:handleAccessorySelect:`, selectedAccessoryId );

      if(this.state.selectedAccessoryId == selectedAccessoryId) {
        selectedAccessoryId = null;
      }

      this.setState({ selectedAccessoryId });
      this.getAccessoryData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, 
                             this.state.selectedPatternId, selectedAccessoryId, this.state.selectedDeckNo);
    }

    handleProductSelect = (selectedProductId) => {
      console.log(`Report4:handleProductSelect:`, selectedProductId );

      if(this.state.selectedProductId == selectedProductId) {
        selectedProductId = null;
      }

      this.setState({ selectedProductId });

      this.getProductData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, 
                           this.state.selectedPatternId, selectedProductId, this.state.selectedDeckNo);
    }

    handleUpdateHoleInfos = (data) => {
      console.log('Report4:handleUpdateHoleInfos:');

      data.holeInfos.map(function(holeInfo){
        if (holeInfo.state == 0) holeInfo.counterStateDescription='OK';
        else {
          if((holeInfo.state & 0x01) > 0)  holeInfo.counterStateDescription+=i18n.t('Wet')+' ';
          if((holeInfo.state & 0x02) > 0)  holeInfo.counterStateDescription+=i18n.t('Collapsed')+' ';
          if((holeInfo.state & 0x04) > 0)  holeInfo.counterStateDescription+=i18n.t('Decked')+' ';
          if((holeInfo.state & 0x08) > 0)  holeInfo.counterStateDescription+=i18n.t('CleanOut')+' ';
          if((holeInfo.state & 0x10) > 0)  holeInfo.counterStateDescription+=i18n.t('ReDrill')+' ';
          if((holeInfo.state & 0x20) > 0)  holeInfo.counterStateDescription+=i18n.t('OverBurden')+' ';
          if((holeInfo.state & 0x40) > 0)  holeInfo.counterStateDescription+=i18n.t('Cracked')+' ';
          if((holeInfo.state & 0x100) > 0) holeInfo.counterStateDescription+=i18n.t('Tacked')+' ';
          if((holeInfo.state & 0x200) > 0) holeInfo.counterStateDescription+=i18n.t('TopLoaded')+' ';
          if((holeInfo.state & 0x400) > 0) holeInfo.counterStateDescription+=i18n.t('OverLoaded');
          if((holeInfo.state & 0x800) > 0) holeInfo.counterStateDescription+=i18n.t('UnderLoaded');
          if((holeInfo.state & 0x1000) > 0) holeInfo.counterStateDescription+=i18n.t('Start');
          if((holeInfo.state & 0x2000) > 0) holeInfo.counterStateDescription+=i18n.t('Hot');
        }
      }) 

      this.setState({ holeInfos:data.holeInfos});
      this.getLoadData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
      this.getSurveyData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
      this.getPatternDaySummary (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
      this.getPatternList (this.props.userDetails.userId, this.props.userDetails.companyId);
      this.getPatternState (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
    }     


    handleUpdateLoadPosition = (index, newCoords) => {

      if(index >= 0) {
        var tempLoadInfos = [...this.state.loadInfos]; //State must be imutable

        tempLoadInfos[index].latitude=newCoords.lat;
        tempLoadInfos[index].longitude=newCoords.lng;

        if(tempLoadInfos[index].noLoads > 1){
          tempLoadInfos[index].noLoads=1;
          tempLoadInfos[index].holeNo='';
        }

        this.setState({loadInfos:tempLoadInfos});
      }

    }

    handlePatternClick = (row) => {
      console.log('Report4:handlePatternClick:', row.siteId, ' ', row.patternNo, ' ', row.approvedState);

      this.setState({ selectedSiteId:row.siteId, selectedPatternApprovedState:row.approvedState, drillLevel:0 , 
                      holeInfos:null, surveyInfos:null, designInfos:null, accessoryInfos:null,
                      layerLoadedVisible:false, layerSurveyVisible:false, layerHoleStateVisible:false, layerDesignVisible:false, layerHeatMapVisible:false, 
                      layerOverFillVisible:false, patternCanvasVisible:false, layerLoadProgressVisible:false, layerVehicleVisible:false,
                      layerAccessoriesVisible:false, layerProductVisible:false, layerSurveyShowGpsPos:false ,
                      layerSurveyOptions:'1', layerHoleStateOptions:'0', layerAccessoriesOptions:'1', selectedAccessoryId:null, selectedProductId:null,patternSummary:row});

      this.getPatternDaySummary       (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getPatternAccessorySummary (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getHoleData                (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getPatternVehicles         (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getPatternDates            (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getPatternState            (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getDesignData              (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getSurveyData              (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getLoadData                (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);
      this.getAccessoryData           (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo, null, this.state.selectedDeckNo);
      this.getProductData             (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo, null, this.state.selectedDeckNo);
      this.getAccessoryPatternDates   (this.props.userDetails.userId, this.props.userDetails.companyId, row.siteId, row.patternNo);

      scroller.scrollTo('scrollDashboard', {
        duration: 100,
        offset: -150,
        delay: 500,
        smooth: 'easeInOutQuart'
      })

    }

    handleUpdateHoleSelect = (selectedHoleId) => {
      console.log('Report4:handleUpdateHoleSelect', selectedHoleId);
      this.setState({selectedHoleId : selectedHoleId});
    }    

    handleUpdatePatternList = (patternList) => {
      console.log('Report4:handleUpdatePatternList', patternList);
      this.setState({patternList : patternList});
      this.getPatternState (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
    }    
	
    handleUpdateMediaFiles = (mediaFiles) => {
      console.log ('Report4:handleUpdateMediaFiles' , mediaFiles)
      this.setState({mediaFiles});
    }     

    handleUpdateStorageProgress = (storageProgress) => {
      this.setState({storageProgress: storageProgress});
    }     

    handleRefreshData = () => {
     this.getLoadData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getHoleData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
    }

    handleSearchClick = () => {
     this.setState({layerLoadedVisible:false, layerSurveyVisible:false, layerHoleStateVisible:false, layerDesignVisible:false, layerHeatMapVisible:false, 
                    holeInfos:null, surveyInfos:null, designInfos:null, accessoryInfos:null,
                    layerOverFillVisible:false, patternCanvasVisible:false, layerLoadProgressVisible:false, layerVehicleVisible:false,
                    layerAccessoriesVisible:false, layerProductVisible:false, layerSurveyShowGpsPos:false,
                    layerSurveyOptions:'1', layerHoleStateOptions:'0', layerAccessoriesOptions:'1', selectedAccessoryId:null, selectedProductId:null});

     this.getPatternDaySummary       (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getPatternAccessorySummary (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getHoleData                (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getPatternVehicles         (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getPatternDates            (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getPatternState            (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId); 
     this.getDesignData              (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getSurveyData              (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getLoadData                (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
     this.getAccessoryData           (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, 
                                      this.state.selectedPatternId,null,this.state.selectedDeckNo);
     this.getProductData             (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, 
                                      this.state.selectedPatternId,null,this.state.selectedDeckNo);
     this.getAccessoryPatternDates   (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
    }


    handleUpdateFilteredHolesInfos = (holeInfos) => {
      console.log('Report4:handleUpdateFilteredHoles');

      const filteredHoleInfos   = [...this.state.holeInfos];
      const filteredSurveyInfos = [...this.state.surveyInfos];

      if (holeInfos.length !== this.state.holeInfos.length) {
        for (let i=0; i< this.state.holeInfos.length; i++){
          let index = holeInfos.findIndex(holeInfo => holeInfo.holeId === this.state.holeInfos[i].holeId);
          if (index === -1) {
            filteredHoleInfos[i].filtered=1;
          } else {
            filteredHoleInfos[i].filtered=0;
          }
        }
  
        for (let i=0; i< this.state.surveyInfos.length; i++){
          let index = holeInfos.findIndex(holeInfo => holeInfo.holeNo === this.state.surveyInfos[i].holeNo);
          if (index === -1) {
            filteredSurveyInfos[i].filtered=1;
          } else {
            filteredSurveyInfos[i].filtered=0;
          }
        }
      }

      this.setState({filteredHoleInfos:filteredHoleInfos, filteredSurveyInfos:filteredSurveyInfos});
    }


    getPatternList = (userId, companyId) => {
      console.log("Report4:getPatternList", userId, " ", companyId);

      fetch(process.env.REACT_APP_HOST_URL+'report4TablePatternList.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
        })
      })
      .then(response => response.json())
      .then(data => {this.setState({ patternList:data})})
      .catch(error => this.setState({ error }));
    }


    getPatternDaySummary = (userId, companyId, siteId, patternId) =>{
      console.log("Report4:getPatternDaySummary", userId, " ", companyId, " " , siteId, " ", patternId);
      fetch(process.env.REACT_APP_HOST_URL+'report4TableDaySummary.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
              userId    : userId,
              companyId : companyId,
              siteId    : siteId,
              patternId : patternId
          })
      })
      .then(response => response.json())
      .then(data => this.setState({ patternDaySummary:data }))
      .catch(error => this.setState({ error }));
    }


    getPatternAccessorySummary = (userId, companyId, siteId, patternId) =>{
      console.log("Report4:getPatternAccessorySummary", userId, " ", companyId, " " , siteId, " ", patternId);
      fetch(process.env.REACT_APP_HOST_URL+'report4TableAccessorySummary.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
              userId    : userId,
              companyId : companyId,
              siteId    : siteId,
              patternId : patternId
          })
      })
      .then(response => response.json())
      .then(data => this.setState({ patternAccessorySummary:data }))
      .catch(error => this.setState({ patternAccessorySummary:null }));
    }



    getPatternState = (userId, companyId, siteId, patternId) => {
      console.log("Report4:getPatternState", userId, " ", companyId, " " , siteId, " ", patternId);

      fetch(process.env.REACT_APP_HOST_URL+'report4PatternStateDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternId : patternId
        })
      })
      .then(response => response.json())
      .then(data => {this.setState({ patternState:data.state});
                     this.setState({ patternUrl:data.url});})
      .catch(error => this.setState({ error }));
    }

    getPatternVehicles = (userId, companyId, siteId, patternId) => {
      console.log("Report4:getPatternVehicles", userId, " ", companyId, " " , siteId, " ", patternId);

      fetch(process.env.REACT_APP_HOST_URL+'report4GetPatternVehiclesDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternId : patternId
        })
      })
      .then(response => response.json())
      .then(data => {this.setState({ patternVehicles:data});
                    })
      .catch(error => this.setState({ patternVehicles:null }));
    }

    getPatternDates = (userId, companyId, siteId, patternId) => {
      console.log("Report4:getPatternDates", userId, " ", companyId, " " , siteId, " ", patternId);

      fetch(process.env.REACT_APP_HOST_URL+'report4GetPatternDatesDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternId : patternId
        })
      })
      .then(response => response.json())
      .then(data => {this.setState({ patternDates:data});
                    })
      .catch(error => this.setState({ patternDates:null }));
    }


    getAccessoryIds = () => {
      console.log("Report4:getAccessoryIds:", this.props.userDetails.userId, this.props.userDetails.companyId);

      fetch(process.env.REACT_APP_HOST_URL+'report4GetAccessoryIdsDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId
        })
      })
      .then(response => response.json())
      .then(data => {this.setState({ accessoryIds:data});
                    })
      .catch(error => this.setState({ accessoryIds:null }));
    }


    getProductIds = () => {
      console.log("Report4:getProductIds:", this.props.userDetails.userId, this.props.userDetails.companyId);

      fetch(process.env.REACT_APP_HOST_URL+'report4GetProductIdsDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : this.props.userDetails.userId,
          companyId : this.props.userDetails.companyId
        })
      })
      .then(response => response.json())
      .then(data => {this.setState({ productIds:data});
                    })
      .catch(error => this.setState({ productIds:null }));
    }


    getHoleData = (userId, companyId, siteId, patternId) => {
      this.getHoleData(userId, companyId, siteId, patternId, false) ;
    }

    getHoleData = (userId, companyId, siteId, patternId, sameVersion) => {
	    console.log("Report4:getHoleData", userId, " ", companyId, " ", siteId, " ", patternId);
      fetch(process.env.REACT_APP_HOST_URL+'commonTableHoleInfoDS5.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternId : patternId
        })
      })
      .then(response => response.json())
      .then(data => { 
         let holeInfoVersion = this.state.holeInfoVersion;

         if (sameVersion===false) holeInfoVersion = this.state.holeInfoVersion+1;

         data.holeInfos.map(function(holeInfo){
           if (holeInfo.state == 0) holeInfo.counterStateDescription='OK';
           else {
             if((holeInfo.state & 0x01) > 0)  holeInfo.counterStateDescription+=i18n.t('Wet')+' ';
             if((holeInfo.state & 0x02) > 0)  holeInfo.counterStateDescription+=i18n.t('Collapsed')+' ';
             if((holeInfo.state & 0x04) > 0)  holeInfo.counterStateDescription+=i18n.t('Decked')+' ';
             if((holeInfo.state & 0x08) > 0)  holeInfo.counterStateDescription+=i18n.t('CleanOut')+' ';
             if((holeInfo.state & 0x10) > 0)  holeInfo.counterStateDescription+=i18n.t('ReDrill')+' ';
             if((holeInfo.state & 0x20) > 0)  holeInfo.counterStateDescription+=i18n.t('OverBurden')+' ';
             if((holeInfo.state & 0x40) > 0)  holeInfo.counterStateDescription+=i18n.t('Cracked')+' ';
             if((holeInfo.state & 0x100) > 0) holeInfo.counterStateDescription+=i18n.t('Tacked')+' ';
             if((holeInfo.state & 0x200) > 0) holeInfo.counterStateDescription+=i18n.t('TopLoaded')+' ';
             if((holeInfo.state & 0x400) > 0) holeInfo.counterStateDescription+=i18n.t('OverLoaded');
             if((holeInfo.state & 0x800) > 0) holeInfo.counterStateDescription+=i18n.t('UnderLoaded');
             if((holeInfo.state & 0x1000) > 0) holeInfo.counterStateDescription+=i18n.t('Start');
             if((holeInfo.state & 0x2000) > 0) holeInfo.counterStateDescription+=i18n.t('Hot');
           }
         })

         this.setState({ holeInfoVersion:holeInfoVersion, selectedPatternId:patternId, holeInfos:data.holeInfos, filteredHoleInfos:null, filteredSurveyInfos:null});

         if (sameVersion===false){
           scroller.scrollTo('scrolltablesummary', {
             duration: 500,
             offset: 0,
             delay: 0,
             smooth: 'easeInOutQuart'
           })
         }
      })
      .catch(error => this.setState({ error }));

      fetch(process.env.REACT_APP_HOST_URL+'commonPeopleDriversDS.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
              userId    : userId,
              companyId : companyId
          })
      })
      .then(response => response.json())
      .then(data => this.setState({ drivers:data }))
      .catch(error => this.setState({ error }));
    
      fetch(process.env.REACT_APP_HOST_URL+'commonPeopleBlastersDS.php',
      {
          method: 'POST',
          body: JSON.stringify(
          {
              userId    : userId,
              companyId : companyId
        })
      })
      .then(response => response.json())
      .then(data => this.setState({ blasters:data }))
      .catch(error => this.setState({ error })); 

      fetch(process.env.REACT_APP_HOST_URL+'commonProductTypesDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
          userId    : userId,
          companyId : companyId,
          typeId    : 1
        })
      })
      .then(response => response.json())
      .then(data => this.setState({ productTypes:data }))
      .catch(error => this.setState({ error }));    

      fetch(process.env.REACT_APP_HOST_URL+'report4TableMediaDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternId : patternId
        })
      })
      .then(response => response.json())
      .then(data => this.setState({ mediaFiles:data,  drillLevel:1 }))
      .catch(error => this.setState({ error }));
    }


    getDesignData = (userId, companyId, siteId, patternId) => {
      console.log("Report4:getDesignData", userId, " ", companyId, " ", siteId, " ", patternId);
      fetch(process.env.REACT_APP_HOST_URL+'setupImportPatternGetHoleInfosDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternId : patternId
        })
      })
      .then(response => response.json())
      .then(data => {
        if ((this.state.designInfos===null)  && //First render
            ((this.state.surveyInfos) && (this.state.surveyInfos.length === 0 ) || (!this.state.surveyInfos)))   {
             this.setState({ designInfos:data, layerLoadedVisible:false, layerSurveyVisible:false,
                                               layerHoleStateVisible:false, layerDesignVisible:true, layerAccessoriesVisible:false,}) ;
        } else { 
          this.setState({designInfos:data});
        }
      })
      .catch(error => this.setState({ designInfos:null }));    
    }


    getSurveyData = (userId, companyId, siteId, patternId) => {
      console.log("Report4:getSurveyData", userId, " ", companyId, " ", siteId, " ", patternId);
      fetch(process.env.REACT_APP_HOST_URL+'getSurveyData6DS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternId : patternId
        })
      })
      .then(response => response.json())
      .then(data => {
        if (this.state.surveyInfos===null) { //First render
          if(data.length > 0) {
            this.setState({surveyInfos:data, layerLoadedVisible:false, layerSurveyVisible:true, 
                           layerHoleStateVisible:false, layerDesignVisible:false, layerAccessoriesVisible:false,}) ; 
          } else if ((this.state.designInfos) && (this.state.designInfos.length>0))  {
            this.setState({surveyInfos:data, layerLoadedVisible:false, layerSurveyVisible:false, 
                           layerHoleStateVisible:false, layerDesignVisible:true,layerAccessoriesVisible:false,}); 
          } else if ((this.state.holeInfos) && (this.state.holeInfos.length>0)) {
            this.setState({surveyInfos:data, layerLoadedVisible:true, layerSurveyVisible:false, 
                           layerHoleStateVisible:false, layerDesignVisible:false,layerAccessoriesVisible:false,}); 
          } else {
            this.setState({surveyInfos:data, layerLoadedVisible:false, layerSurveyVisible:false, layerDesignVisible:true,
                           layerHoleStateVisible:false, layerAccessoriesVisible:false,});
          }
        } else {
          console.log("Report4:getSurveyData", data);
          this.setState({surveyInfos:data});
        }
      })
      .catch(error => this.setState({ surveyInfos:null }));
    }


    getLoadData = (userId, companyId, siteId, patternId) => {
      console.log("Report4:getLoadData", userId, " ", companyId, " ", siteId, " ", patternId);
      fetch(process.env.REACT_APP_HOST_URL+'commonGetLoadsTempDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternNo : patternId
        })
      })
      .then(response => response.json())
      .then(data => {
          if(data.length > 0) {
            this.setState({loadInfos:data, layerLoadedVisible:this.layerSurveyVisible?false:true});
          } else {
            this.setState({loadInfos:data});
          }
      })
      .catch(error => this.setState({ loadInfos:null }));
    }


    getAccessoryData = (userId, companyId, siteId, patternId, accessoryId, deckNo) => {
      console.log("Report4:getAccessoryData", userId, companyId, siteId, patternId, accessoryId, deckNo);

      fetch(process.env.REACT_APP_HOST_URL+'getAccessoryDataDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId      : userId,
            companyId   : companyId,
            siteId      : siteId,
            patternNo   : patternId,
            accessoryId : accessoryId,
            deckNo      : deckNo
        })
      })
      .then(response => response.json())
      .then(data => {
          this.setState({accessoryInfos:data});
      })
      .catch(error => this.setState({ accessoryInfos:null }));
    }

    getProductData = (userId, companyId, siteId, patternId, productId, deckNo) => {
      console.log("Report4:getProductData", userId, companyId, siteId, patternId, productId, deckNo);

      fetch(process.env.REACT_APP_HOST_URL+'getProductDataDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId      : userId,
            companyId   : companyId,
            siteId      : siteId,
            patternNo   : patternId,
            productId   : productId,
            deckNo      : deckNo
        })
      })
      .then(response => response.json())
      .then(data => {
          this.setState({productInfos:data});
      })
      .catch(error => this.setState({ productInfos:null }));
    }


    getAccessoryPatternDates = (userId, companyId, siteId, patternId) => {
      console.log("Report4:getAccessoryPatternDates", userId, " ", companyId, " ", siteId, " ", patternId);
      fetch(process.env.REACT_APP_HOST_URL+'getAccessoryPatternDates.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
            userId    : userId,
            companyId : companyId,
            siteId    : siteId,
            patternNo : patternId
        })
      })
      .then(response => response.json())
      .then(data => {
          this.setState({accessoryPatternDates:data});
      })
      .catch(error => this.setState({ accessoryPatternDates:null }));
    }

    refreshData = (options) => {
      console.log ("Report4:refreshData", options);
      if ((options&1)>0) 
        this.getSurveyData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);

      if ((options&32)>0)
        this.getLoadData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);

      if ((options&2)>0) 
        this.getHoleData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);

      if ((options&4)>0)
        this.getPatternDaySummary (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);

      if ((options&8)>0)
        this.getPatternList (this.props.userDetails.userId, this.props.userDetails.companyId);

      if ((options&16)>0)
        this.getAccessoryData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId,
                               this.state.selectedPatternId, this.state.selectedAccessoryId, this.state.selectedDeckNo);

      if ((options&64)>0)
        this.getDesignData (this.props.userDetails.userId, this.props.userDetails.companyId, this.state.selectedSiteId, this.state.selectedPatternId);
    }

    handleKeyDown = (event) => {
    }

    handleKeyUp = (event) => {
    }

    handleApproveClick = () => {
      console.log("Report.4:handleApproveClick");
      fetch(process.env.REACT_APP_HOST_URL+'report4UpdatePatternStateDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.state.selectedSiteId,
           patternId : this.state.selectedPatternId,
           state     : 1
        })
      })
      .then(response => response.json())
      .then(data =>this.handleUpdatePatternList(data) )
      .catch(error => this.setState({ error, isLoading:true }));
    }

    handleUnlockClick = () => {
      console.log("Report.4:handleUnlockClick");
      fetch(process.env.REACT_APP_HOST_URL+'report4UpdatePatternStateDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.state.selectedSiteId,
           patternId : this.state.selectedPatternId,
           state     : 3
        })
      })
      .then(response => response.json())
      .then(data =>this.handleUpdatePatternList(data) )
      .catch(error => this.setState({ error, isLoading:true }));
    }

    handleBlastReportPdfClick = () => {
      console.log("Report.4:handleBlastReportPdfClick");
      fetch(process.env.REACT_APP_HOST_URL+'pdfBlastReport.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.state.selectedSiteId,
           patternId : this.state.selectedPatternId,
        })
      })
      .then(response => {
        response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'iBlastXBlastReport_' + this.state.selectedPatternId + '.pdf';
          alink.click();
        })
      })
    }

    handleDrillReportPdfClick = () => {
      console.log("Report.4:handleDrillReportPdfClick");
      fetch(process.env.REACT_APP_HOST_URL+'pdfDrillReport.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.state.selectedSiteId,
           patternId : this.state.selectedPatternId,
        })
      })
      .then(response => {
        response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'iBlastXDrillReport_' + this.state.selectedPatternId + '.pdf';
          alink.click();
        })
      })
    }

    handleBlastXClick = () => {
      console.log("Report.4:handleBlastXClick");
      fetch(process.env.REACT_APP_HOST_URL+'getBlastXPatternDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.state.selectedSiteId,
           patternId : this.state.selectedPatternId,
        })
      })
      .then(response => response.text())
      .then( data =>{
          var fileDownload = require('js-file-download');
          fileDownload(data, this.state.selectedPatternId+'.BlastX');
       })
      .catch(error => this.setState({ error, isLoading:true }));
    }


    handleCsvClick = () => {
      console.log("Report.4:handleCsvClick");
      fetch(process.env.REACT_APP_HOST_URL+'getCsvPatternDS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.state.selectedSiteId,
           patternId : this.state.selectedPatternId,
        })
      })
      .then(response => response.text())
      .then( data =>{
          var fileDownload = require('js-file-download');
          fileDownload(data, this.state.selectedPatternId+'.csv');
       })
      .catch(error => this.setState({ error, isLoading:true }));
    }

    handleCsv2Click = () => {
      console.log("Report.4:handleCsv2Click");
      fetch(process.env.REACT_APP_HOST_URL+'getCsvPattern2DS.php',
      {
        method: 'POST',
        body: JSON.stringify(
        {
           userId    : this.props.userDetails.userId,
           companyId : this.props.userDetails.companyId,
           siteId    : this.state.selectedSiteId,
           patternId : this.state.selectedPatternId,
        })
      })
      .then(response => response.text())
      .then( data =>{
          var fileDownload = require('js-file-download');
          fileDownload(data, this.state.selectedPatternId+'.csv');
       })
      .catch(error => this.setState({ error, isLoading:true }));
    }



     handleCopyClick = () => {
      console.log("Report.4:handleCopyClick:",this.state.patternUrl);

      this.copyToClipboard(this.state.patternUrl)
        .then(() => console.log('text copied !'))
        .catch(() => console.log('error'));
     }


     copyToClipboard(textToCopy) {
       // navigator clipboard api needs a secure context (https)
       if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
       } else {
          // text area method
         let textArea = document.createElement("textarea");
         textArea.value = textToCopy;
        // make the textarea out of viewport
         textArea.style.position = "fixed";
         textArea.style.left = "-999999px";
         textArea.style.top = "-999999px";
         document.body.appendChild(textArea);
         textArea.focus();
         textArea.select();
         return new Promise((res, rej) => {
              // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
         });
      }
    }


      render() {       
        const { t } = this.props;
        const {selectedSites,selectedPattern} = this.state;      

        const progressContainerStyle = {
          margin : '0 auto', 
          width: '250px',
        };

        let now = dayjs();
        let initialDate = now.subtract('1', 'month')
        let initialStartDate = now.subtract('2', 'month');
        let initialEndDate =now;

        var weekdays = [t('Sunday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday')];
        var weekdaysShort = [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thur'), t('Fri'), t('Sat')];

        const locale = {
          name: 'custom',
          weekdays:weekdays,
          weekdaysShort:weekdaysShort,
          months: '1,2,3,4,5,6,7,8,9,10,11,12'.split(','),
        };


        return (
        <div>
	         {(this.state.searchEnabled===true) && [
            <div key='R4.1' >            
              <h3 key='R4.2' style={{textAlign: 'center', color: 'black'}}>{t('Pattern_Report')}</h3>
              <div key='R4.3' style={{ float:'left', maxWidth: '500px', margin: 'auto' }}> 
                <RangeDatePicker onChange={this.onChange}
                    locale={locale}
                    showMonthCnt={3}
                    initialDate ={initialDate}
                    initialStartDate ={initialStartDate}
                    initialEndDate= {initialEndDate}
                    startPlaceholder={t('Start_Date')}
                    endPlaceholder={t('End_Date')}/>  
              </div>
              <div key='R4.4' style={{ clear :'both', width: '400px' }}> 
                <Select
                  value={selectedSites}
                  onChange={this.handleChangeSites}
                  options={this.state.sites}
                  isMulti ={false}
                  placeholder ={t('Select_Site')}
                />
              </div> 
              <div key='R4.5' style={{ clear :'both', width: '400px' }}>
                <Select
                  value={selectedPattern}
                  onChange={this.handleChangePatterns}
                  options={this.state.patterns}
                  isMulti ={false}
                  placeholder ={t('Select_Pattern')}
                />
              </div>
            </div>

          ]}

          {((this.state.startDate!=null) && (this.state.endDate!=null) &&
            (this.state.selectedSiteId !=null) && (this.state.selectedPatternId!=null) && (this.state.searchEnabled===true)) && [
            <div key='R4.6' style={{ clear :'both'}}>
              <Button onClick={this.handleSearchClick} variant="primary">Search</Button>
            </div>
          ]}          


          {(this.state.patternList!=null) && [
          <div key='R4.7' style={{paddingTop: '20px'}}>
            <TablePatternList
               key='R4.8' 
               onPatternClick={this.handlePatternClick}
               userDetails={this.props.userDetails}
               patternList={this.state.patternList}/>
               </div>
          ]}


          <Element name='scrollDashboard' className='element'/>

          {(this.state.drillLevel >0) && [
            <div key='R4.9'  style={{clear :'both'}}>

            {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_PATTERN_DAILY_SUMMARY)>0 && [
              <div>
                <h3
                  key= 'R4.10' 
                  style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>
                  {this.state.selectedPatternId} {t('DashBoard')}
                </h3>
                {(this.state.patternSummary != null)&& [
                  <div key='R4.11' >
                    <DashBoard
                      userDetails    = {this.props.userDetails}
                      siteId         = {this.state.selectedSiteId}
                      patternId      = {this.state.selectedPatternId}
                      patternSummary = {this.state.patternSummary} />
                  </div>
                ]}
              </div>]}

              {(this.state.patternDaySummary != null) && (this.state.patternDaySummary.length > 0) &&
               ((this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_PATTERN_DAILY_SUMMARY)>0) && [
              <div style={{ clear:'both'}}>
                <h3
                  key= 'R4.12' 
                  style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>
                  {t('Daily_Summary')}
                </h3>
  	            <TableDailySummary
                  userDetails={this.props.userDetails}
                  siteId={this.state.selectedSiteId}
                  patternDaySummary ={this.state.patternDaySummary}
                  patternId={this.state.selectedPatternId}/>
              </div>]}


              {(this.state.patternAccessorySummary != null) && (this.state.patternAccessorySummary.length > 0) && 
               ((this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_PATTERN_DAILY_SUMMARY)>0) && [
              <div style={{ clear:'both'}}>
                <h3
                  key='R4.13' 
                  style={{ captionSide: 'top', borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>
                  {t('Accessory_Summary')}
                </h3>
                <TableAccessorySummary
                  userDetails={this.props.userDetails}
                  siteId={this.state.selectedSiteId}
                  patternAccessorySummary ={this.state.patternAccessorySummary}
                  patternId={this.state.selectedPatternId}/>
              </div>]}

              {((this.state.holeInfos != null) && (this.state.patternState != null)) && [
                <div style={{paddingBottom: '10px'}}>
                  <TableHoleInfo              
                    onUpdateHoleInfos         = {this.handleUpdateHoleInfos}
                    onUpdateHoleSelect        = {this.handleUpdateHoleSelect}
	                  onUpdatePatternList       = {this.handleUpdatePatternList}
                    onUpdateFilteredHoleInfos = {this.handleUpdateFilteredHolesInfos}
                    patternApprovedState      = {this.state.patternState.approvedState}
                    userDetails               = {this.props.userDetails}
                    siteId                    = {this.state.selectedSiteId}
                    holeInfos                 = {this.state.holeInfos}
                    patternId                 = {this.state.selectedPatternId}
                    drivers                   = {this.state.drivers}
	                  productTypes              = {this.state.productTypes}
                    blasters                  = {this.state.blasters}
                    refreshData               = {this.refreshData}/>

                 {(this.props.userDetails.flags&AuthoriseObj.AUTH_EDIT_PATTERN_EXPORT_CSV>0 ?
                  (<div>
                     <div style={{  float:'left',paddingRight: '5px'}}>
                       <Button 
                         onClick={this.handleCsvClick} 
                         variant="primary">
                         {t('CSV_V1')}
                       </Button>
                     </div>
                     <div style={{  float:'left',paddingRight: '5px'}}>
                       <Button 
                         onClick={this.handleCsv2Click} 
                         variant="primary">
                         {t('CSV_V2')}
                       </Button>
                     </div>
                   </div>):null
                 )}
              </div> ]}

           {(this.state.selectedPatternId!=null ?
              (this.state.patternState==1 ?
               (<div  style={{clear:'both', paddingTop: '5px'}}>
                 {(this.props.userDetails.flags&AuthoriseObj.AUTH_APPROVE_PATTERN>0 ?
                   <Button onClick={this.handleUnlockClick} variant="primary">{t('Pattern_Unlock')}</Button>:null
                 )}

                 {' '}<Button onClick={this.handleBlastXClick} variant="primary">{t('Export_BlastX')}</Button>
                 {' '}<Button onClick={this.handleCopyClick} variant="primary">{t('Export_Link')}</Button>
               </div>)
              :
               (this.props.userDetails.flags&AuthoriseObj.AUTH_APPROVE_PATTERN>0 ?(
                 <div style={{clear:'both', paddingTop: '5px'}}>
                   <Button 
                     onClick={this.handleApproveClick} 
                     variant="primary">
                     {t('Pattern_Approve')}
                   </Button>
                 </div>):null
               )
             )
           : null)
           }

                
           {false&& [
             <div>
               <h3 key='R4.15'  style={{ borderRadius: '0.25em', textAlign: 'center', color: 'black', border: '1px solid black' }}>{t('Media')}</h3>
               <StyledDropzone
                  key='R4.16' 
                  onUpdateMediaFiles={this.handleUpdateMediaFiles}
                  updateStorageProgress={this.handleUpdateStorageProgress}
                  userDetails={this.props.userDetails}
                  siteId={this.state.selectedSiteId}
                  patternId={this.state.selectedPatternId}/>
                  <div key='R4.17'  style={progressContainerStyle}>
                    <ProgressBar now={this.state.storageProgress} label={`${this.state.storageProgress}%`} />
                  </div> 
  
                {(this.state.mediaFiles != null) &&[
                  <TableMedia
                    key='R4.18' 
                    onUpdateMediaFiles={this.handleUpdateMediaFiles}
                    userDetails={this.props.userDetails}
                    siteId={this.state.selectedSiteId}
                    mediaFiles={this.state.mediaFiles}
                    patternId={this.state.selectedPatternId}/>]}
                </div>
           ]}

           {(this.props.userDetails.flags&AuthoriseObj.AUTH_EDIT_PATTERN_EXPORT_CSV)>0 && [
             <div key ='R4.120' style={{paddingTop: '5px'}}>
               <div style={{  float:'left',paddingRight: '5px'}}>
                 <Button 
                   onClick={this.handleBlastReportPdfClick} 
                   variant="primary">
                   {t('BlastReport_PDF')}
                 </Button>
               </div>

               <div style={{  float:'left',paddingRight: '5px'}}>
                 <Button 
                   onClick={this.handleDrillReportPdfClick} 
                   variant="primary">
                   {t('DrillReport_PDF')}
                 </Button>
               </div>
             </div>
           ]}

           <div key ='R4.100' style={{clear:'both', paddingTop: '10px'}}>
             <ButtonGroup toggle className="mb-2">
               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="1"
                   onChange={(e) =>
                     this.setState({layerSurveyVisible:e.currentTarget.checked, 
                                    layerHoleStateVisible:false, layerVehicleVisible:false, layerLoadProgressVisible:false, layerSurveyShowGpsPos:false,
                                    patternCanvasVisible:false, layerDesignVisible:false, layerAccessoriesVisible:false, layerProductVisible:false, 
                                    layerLoadedVisible:false})}
                   checked={this.state.layerSurveyVisible}>
                 {t('Survey')}
                 </ToggleButton> 
               ]}

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="1"
                   onChange={(e) => 
                     this.setState({layerAccessoriesVisible:e.currentTarget.checked,
                                    layerHoleStateVisible:false, layerVehicleVisible:false, layerLoadProgressVisible:false, layerProductVisible:false,
                                    patternCanvasVisible:false, layerDesignVisible:false, layerSurveyVisible:false, layerOverFillVisible:false, 
                                    layerSurveyShowGpsPos:false, layerLoadedVisible:false})}
                   checked={this.state.layerAccessoriesVisible}>
                 {t('Accessories')}
                 </ToggleButton>
               ]}

                <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="8"
                   onChange={(e) =>
                     this.setState({layerProductVisible:e.currentTarget.checked,
                                    layerOverFillVisible:false, layerSurveyVisible:false, layerVehicleVisible:false, layerHoleStateVisible:false,
                                    layerLoadProgressVisible:false, patternCanvasVisible:false, layerDesignVisible:false, layerAccessoriesVisible:false,
                                    layerSurveyShowGpsPos:false, layerLoadedVisible:false})}
                   checked={this.state.layerHoleStateVisible}>
                 {t('Product')}
                 </ToggleButton>

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="2"
                   onChange={(e) => 
                     this.setState({layerLoadedVisible:e.currentTarget.checked, 
                                    patternCanvasVisible:false, layerHoleStateVisible:false, layerSurveyVisible:false, layerProductVisible:false,
                                    layerVehicleVisible:false, layerLoadProgressVisible:false, layerAccessoriesVisible:false,
                                    layerSurveyShowGpsPos:false})}
                   checked={this.state.layerLoadedVisible}>
                   {t('Loaded')} 
                 </ToggleButton>
               ]}

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="3"
                   onChange={(e) => 
                     this.setState({layerDesignVisible:e.currentTarget.checked, 
                                    patternCanvasVisible:false, layerHoleStateVisible:false, layerSurveyVisible:false, layerProductVisible:false,
                                    layerVehicleVisible:false, layerLoadProgressVisible:false, layerAccessoriesVisible:false,
                                    layerSurveyShowGpsPos:false})}
                   checked={this.state.layerDesignVisible}>
                 {t('Design')}
                 </ToggleButton>
               ]}

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="4"
                   onChange={(e) => 
                     this.setState({layerHeatMapVisible:e.currentTarget.checked,
                                    patternCanvasVisible:false})}
                   checked={this.state.layerHeatMapVisible}>
                 {t('Heatmap')}
                 </ToggleButton>
               ]}

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="5"
                   onChange={(e) => 
                     this.setState({layerOverFillVisible:e.currentTarget.checked,
                                    patternCanvasVisible:false, layerHoleStateVisible:false, layerVehicleVisible:false, layerProductVisible:false,
                                    layerLoadProgressVisible:false, layerDesignVisible:false, layerAccessoriesVisible:false,
                                    layerSurveyShowGpsPos:false, layerLoadedVisible:false})}
                   checked={this.state.layerOverFillVisible}>
                   {t('Over_Fill')}
                 </ToggleButton>
               ]}

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="6"
                   onChange={(e) => 
                     this.setState({layerLoadProgressVisible:e.currentTarget.checked, 
                                    patternCanvasVisible:false, layerLoadedVisible:false, layerHoleStateVisible:false, layerProductVisible:false,
                                    layerSurveyVisible:false, layerVehicleVisible:false, layerOverFillVisible:false, 
                                    layerDesignVisible:false, layerAccessoriesVisible:false, layerSurveyShowGpsPos:false})}
                   checked={this.state.layerLoadProgressVisible}>
                   {t('Load_Progress')}
                 </ToggleButton>
               ]}

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="7"
                   onChange={(e) => 
                     this.setState({layerVehicleVisible:e.currentTarget.checked, 
                                    patternCanvasVisible:false, layerLoadedVisible:false, layerHoleStateVisible:false, layerProductVisible:false,
                                    layerSurveyVisible:false, layerLoadProgressVisible:false, layerOverFillVisible:false,
                                    layerDesignVisible:false, layerAccessoriesVisible:false, layerSurveyShowGpsPos:false})}
                   checked={this.state.layerVehicleVisible}>
                   {t('Vehicles')}
                 </ToggleButton>
               ]}

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="8"
                   onChange={(e) => 
                     this.setState({layerHoleStateVisible:e.currentTarget.checked,
                                    layerOverFillVisible:false, layerSurveyVisible:false, layerVehicleVisible:false, layerProductVisible:false,
                                    layerLoadProgressVisible:false, patternCanvasVisible:false, layerDesignVisible:false, layerAccessoriesVisible:false,
                                    layerSurveyShowGpsPos:false, layerLoadedVisible:false})}
                   checked={this.state.layerHoleStateVisible}>
                 {t('HoleState')}
                 </ToggleButton>
               ]}

               {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_MAP_LAYERS)>0 && [
                  <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value="9"
                   onChange={(e) => this.setState({patternCanvasVisible:e.currentTarget.checked})}
                   checked={this.state.patternCanvasVisible}>
                   {t('PNG')}
                 </ToggleButton>
               ]}

             </ButtonGroup>
           </div>

           {this.state.layerAccessoriesVisible?
           <div key='R4.101'>
             <ButtonGroup toggle className="mb-2">
               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value='1'
                 onChange={(e) => this.setState({layerAccessoriesOptions:e.currentTarget.value})}
                 checked={this.state.layerAccessoriesOptions==='1'}>
                 {t('AccessoryQuantity')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value='2'
                 onChange={(e) => this.setState({layerAccessoriesOptions:e.currentTarget.value})}
                 checked={this.state.layerAccessoriesOptions==='2'}>
                 {t('AccessoryProgress')}
               </ToggleButton>
             </ButtonGroup>
           </div>:null
           }

           {this.state.layerSurveyVisible?
           <div key = 'R4.102'>
             <ButtonGroup toggle className="mb-2">
               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value='1'
                 onChange={(e) => this.setState({layerSurveyOptions:e.currentTarget.value})}
                 checked={this.state.layerSurveyOptions==='1'}>
                 {t('SurveyLatest')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value='2'
                 onChange={(e) => this.setState({layerSurveyOptions:e.currentTarget.value})}
                 checked={this.state.layerSurveyOptions==='2'}>
                 {t('SurveyDepth')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value='3'
                 onChange={(e) => this.setState({layerSurveyOptions:e.currentTarget.value})}
                 checked={this.state.layerSurveyOptions==='3'}>
                 {t('SurveyLoaded')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value='4'
                 onChange={(e) => this.setState({layerSurveyOptions:e.currentTarget.value})}
                 checked={this.state.layerSurveyOptions==='4'}>
                 {t('SurveyStemming')}
               </ToggleButton>
             </ButtonGroup>

             <div key='R4.105' style={{  float:'left',paddingRight: '10px'}}>
               <ButtonGroup toggle className="mb-2">
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value='5'
                   onChange={(e) => this.setState({layerSurveyOptions:e.currentTarget.value})}
                   checked={this.state.layerSurveyOptions==='5'}>
                   {t('SurveyPostDriling')}
                 </ToggleButton>
  
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value='6'
                   onChange={(e) => this.setState({layerSurveyOptions:e.currentTarget.value})}
                   checked={this.state.layerSurveyOptions==='6'}>
                   {t('SurveyPreLoading')}
                 </ToggleButton>
  
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value='7'
                   onChange={(e) => this.setState({layerSurveyOptions:e.currentTarget.value})}
                   checked={this.state.layerSurveyOptions==='7'}>
                   {t('SurveyPostLoading')}
                 </ToggleButton>
  
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value='8'
                   onChange={(e) => this.setState({layerSurveyOptions:e.currentTarget.value})}
                   checked={this.state.layerSurveyOptions==='8'}>
                   {t('SurveyPreStemming')}
                 </ToggleButton>
               </ButtonGroup>

               <div ikey='R4.106' style={{  float:'left',paddingRight: '10px'}}>
                 <ButtonGroup toggle className="mb-2">
                   <ToggleButton
                     type="checkbox"
                     variant="outline-primary"
                     size="sm"
                     onChange={(e) => this.setState({layerSurveyShowGpsPos:e.currentTarget.checked})}
                     checked={this.state.layerSurveyShowGpsPos}>
                     {t('GPS_Position')}
                   </ToggleButton>
                 </ButtonGroup>
               </div>

             </div>
            </div>:null
           }

           {this.state.layerHoleStateVisible?
           <div key='R4.107'>
             <ButtonGroup toggle className="mb-2">
               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x01}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x0001)>0)?true:false}>
                 {t('Wet')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x02}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x0002)>0)?true:false}>
                 {t('Collapsed')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x04}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x04)>0)?true:false}>
                 {t('Decked')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x08}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x08)>0)?true:false}>
                 {t('CleanOut')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x10}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x10)>0)?true:false}>
                 {t('ReDrill')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x20}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x20)>0)?true:false}>
                 {t('OverBurden')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x40}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x40)>0)?true:false}>
                 {t('Cracked')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x100}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x100)>0)?true:false}>
                 {t('Tacked')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x2000}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x2000)>0)?true:false}>
                 {t('Hot')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x200}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x200)>0)?true:false}>
                 {t('TopLoaded')}
               </ToggleButton>

               <ToggleButton
                 type="checkbox"
                 variant="outline-primary"
                 size="sm"
                 value={0x1000}
                 onChange={(e) => this.setState({layerHoleStateOptions:this.state.layerHoleStateOptions^e.currentTarget.value})}
                 checked={((this.state.layerHoleStateOptions&0x1000)>0)?true:false}>
                 {t('Start')}
               </ToggleButton>

             </ButtonGroup>
           </div>:null
           }


           {this.state.layerOverFillVisible?
             <div key ='R4.108'>
               <ButtonGroup toggle className="mb-2">
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value='1'
                   onChange={(e) => this.setState({layerOverFillOptions:e.currentTarget.value})}
                   checked={this.state.layerOverFillOptions==='1'}>
                   {t('OverFillDesign')}
                 </ToggleButton>
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value='2'
                   onChange={(e) => this.setState({layerOverFillOptions:e.currentTarget.value})}
                   checked={this.state.layerOverFillOptions==='2'}>
                   {t('OverFillSurvey')}
                 </ToggleButton>
                 <ToggleButton
                   type="checkbox"
                   variant="outline-primary"
                   size="sm"
                   value='3'
                   onChange={(e) => this.setState({layerOverFillOptions:e.currentTarget.value})}
                   checked={this.state.layerOverFillOptions==='3'}>
                   {t('OverFillIdeal')}
                 </ToggleButton>
               </ButtonGroup>
              </div>:null
             }


             {this.state.patternCanvasVisible?
               <PatternCanvas
                 key= 'R4.109'
                 holeInfos = {this.state.holeInfos}
                 patternNo = {this.state.selectedPatternId}
               />:null}


             {((this.state.holeInfos!==null)    && (this.state.designInfos!==null) && (this.state.productInfos!==null) &&
               (this.state.surveyInfos!==null)  && (this.state.loadInfos!==null) && (this.state.accessoryInfos!==null) &&
               (this.state.patternState!==null) && (!this.state.patternCanvasVisible))&& [
              <div>
                <Map    
                  key='R4.19' 
                  //mapRef={this.mapRef}
		              onUpdateHoleInfos={this.handleUpdateHoleInfos}
		              handleUpdateLoadPosition={this.handleUpdateLoadPosition}
                  refreshData={this.refreshData}
                  userDetails={this.props.userDetails}
                  siteId={this.state.selectedSiteId}
                  holeInfos={this.state.filteredHoleInfos==null?this.state.holeInfos:this.state.filteredHoleInfos}
                  patternVehicles={this.state.patternVehicles}
                  patternDates={this.state.patternDates}
                  accessoryIds={this.state.accessoryIds}
                  productIds={this.state.productIds}
                  designInfos={this.state.designInfos}
                  surveyInfos={this.state.filteredSurveyInfos==null?this.state.surveyInfos:this.state.filteredSurveyInfos}
                  loadInfos={this.state.loadInfos}
                  accessoryInfos={this.state.accessoryInfos}
                  productInfos={this.state.productInfos}
                  accessoryPatternDates={this.state.accessoryPatternDates}
                  selectedAccessoryId={this.state.selectedAccessoryId}
                  selectedProductId={this.state.selectedProductId}
                  handleAccessorySelect={this.handleAccessorySelect}
                  handleProductSelect={this.handleProductSelect}
                  holeInfoVersion={this.state.holeInfoVersion}
                  selectedHoleId={this.state.selectedHoleId}
		              date={null}
		              startDate={null}
		              endDate={null}
		              vehicleId={null}
                  layerLoadedVisible={this.state.layerLoadedVisible}
                  layerSurveyVisible={this.state.layerSurveyVisible}
                  layerSurveyOptions={this.state.layerSurveyOptions}
                  layerSurveyShowGpsPos={this.state.layerSurveyShowGpsPos}
                  layerAccessoriesVisible={this.state.layerAccessoriesVisible}
                  layerAccessoriesOptions={this.state.layerAccessoriesOptions}
                  layerHoleStateVisible={this.state.layerHoleStateVisible}
                  layerHoleStateOptions={this.state.layerHoleStateOptions}
                  layerDesignVisible={this.state.layerDesignVisible}
                  layerHeatMapVisible={this.state.layerHeatMapVisible}
                  layerOverFillVisible={this.state.layerOverFillVisible}
                  layerOverFillOptions={this.state.layerOverFillOptions}
                  layerLoadProgressVisible={this.state.layerLoadProgressVisible}
                  layerProductVisible={this.state.layerProductVisible}
                  layerVehicleVisible={this.state.layerVehicleVisible}
                  patternId={this.state.selectedPatternId}/>

                  {(this.props.userDetails.flags&AuthoriseObj.AUTH_SEE_KPI)>0 && [
                    <Chart1
                      key='R4.20' 
                      onUpdateHoleSelect={this.handleUpdateHoleSelect}
                      userDetails={this.props.userDetails}
                      siteId={this.state.selectedSiteId}
                      patternId={this.state.selectedPatternId}/>
                  ]}
               </div>
               ]}
             </div>
          ]}
        </div>     
    );
  }
}

export default withTranslation()(Report4);
