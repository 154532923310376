import React, { Component } from "react";
import UserSummaryTable from './AdminUsersTableSummary';
import AuthoriseObj from './Authorise.js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
 
class AdminUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {         
      usersInfos: null,
      description:'',
      companies:null,
      units:null,
    };
    
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleSubmit            = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getUserInfos();
    this.getCompanies();
    this.getUnits();
  }

  handleChangeDescription(event) {
    this.setState({description: event.target.value});
  }

  getUserInfos = () => {
    console.log("AdminUsers:getUserInfos", this.props.userDetails.userId);
    fetch(process.env.REACT_APP_HOST_URL+'adminGetUserInfosDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        companyId : this.props.userDetails.companyId,
        userId : this.props.userDetails.userId,
      })
    })
    .then(response => response.json())
    .then(data => {
       this.setState({userInfos:data});
    })
    .catch(error => this.setState({ userInfos:null }));
  }


  getCompanies(){
    console.log ("AdminUsersTableSummary:getCompanies");
    fetch(process.env.REACT_APP_HOST_URL+'adminGetCompaniesDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => this.setState({companies:data}))
    .catch(error => this.setState({companies:null}));
  };


  getUnits(){
    console.log ("AdminUsersTableSummary:getUnits");
    fetch(process.env.REACT_APP_HOST_URL+'adminGetUnitsDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId    : this.props.userDetails.userId,
        companyId : this.props.userDetails.companyId
      })
    })
    .then(response => response.json())
    .then(data => this.setState({units:data}))
    .catch(error => this.setState({units:null}));
  };


  handleSubmit(event) {
    console.log("AdminUsers:handleSubmit");
    fetch(process.env.REACT_APP_HOST_URL+'adminAddUserDS.php',
    {
      method: 'POST',
      body: JSON.stringify(
      {
        userId      : this.props.userDetails.userId,
        companyId   : this.props.userDetails.companyId,
        description : this.state.description,
      })
    })
    .then(response => response.json())
    .then(data => {this.getCompanyInfos(); this.setState({description:''});});
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        {((this.state.userInfos) && (this.state.companies) && (this.state.units)) &&[
          <UserSummaryTable key={0}           
            userDetails     = {this.props.userDetails} 
            companyId       = {this.props.userDetails.companyId}
            userInfos       = {this.state.userInfos}
            companies       = {this.state.companies}
            units           = {this.state.units}
            getUserInfos    = {this.getUserInfos} />
        ]}

        {((this.props.userDetails.flags & AuthoriseObj.AUTH_ROOT) > 0) && [
          <div>
            <h4> {t('Add_New_User')} </h4>
            <Form key={1} onSubmit={this.handleSubmit}>
              <Form.Row key={2}>
                <Form.Group controlId="formCompany">
                  <Form.Label>{t('User_Name')}</Form.Label>
                  <Form.Control type="plaintext" placeholder={t('New_User_Name')} onChange={this.handleChangeDescription}
                    value={this.state.description}/>
                </Form.Group>
              </Form.Row>

              <Button key={4} variant="primary" type="submit">{t('Add')}</Button>
            </Form>
          </div>
        ]}

      </div>
    );
  }
}

export default withTranslation()(AdminUsers);
