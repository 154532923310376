import React, { Component } from "react";
import adbInstall from '../downloads/installAppV1_01.apk'
import adbTablet from '../downloads/driverAppV1_75.apk'
import presentationPdf from '../downloads/iBlastXwebpresentation.pdf'
import flyerPdf from '../downloads/iBlastXFlyer.pdf'
import installPdf from '../downloads/iBlastXInstall.pdf'
import reachSetupPdf from '../downloads/iBlastXReachSetup.pdf'
import overviewPdf from '../downloads/iBlastXOverView.pdf'
import ReactPlayer from 'react-player'
import { withTranslation } from 'react-i18next';
 
class Downloads extends Component {
  render() {
  const { t } = this.props;

    return (
      <div>
       <h3>{t('Documentation')}</h3>
        <div style={{clear: 'both', float:'left', width:'100%'}}>
          <ul>
            <li><a href={installPdf}>{t('IBX_Install_Manual')} 1.0 2024-10-29</a></li>
            <li><a href={reachSetupPdf}>{t('Reach_Setup_Manual')} 1.0 2024-10-31</a></li>
          </ul>
        </div>

         <h3>{t('Android_Applications')}</h3>
        <div style={{clear: 'both', float:'left', width:'100%'}}>
          <ul>
            <li><a href={adbInstall}>{t('Android_Install_Application_Version')} 1.0 2024-10-25</a></li>
            <li><a href={adbTablet}>{t('Android_Application_Version')} 1.75 2025-02-10</a></li>
          </ul>
        </div>


        <h3>{t('Presentations')}</h3>
        <div style={{clear: 'both', float:'left', width:'100%'}}>
          <ul>
            <li><a href={presentationPdf}>{t('iBlastX Presentation')} 2021-07-13</a></li>
            <li><a href={flyerPdf}>{t('iBlastX Flyer')} 2022-08-11</a></li>
            <li><a href={overviewPdf}>{t('iBlastX Overview')} 2022-08-11</a></li>
          </ul>
        </div>

        <h3>{t('Videos')}</h3> 
        <div style={{ display:'flex', flexWrap:'wrap'}}>
        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/fNqYYK8W07c?si=zLuKoYweNhuTlUpe' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/T-Lt4FgGY8s?si=hb2LcgXTbHPvBSDs' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/FEIvIhym3KI?si=_Rfyit2DRmgEFPYq' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/B6A5FAKZ0tA?si=LkJix-YVdyelLEKI' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/xGpGNgMLZVo?si=ujUXUVhc4Pvs3ykm' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/eJ_zlxETNMk?si=iY-qaYcD5cRAtS7n' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/-DMsIPo3zi8?si=RUTRbe8O89i4ya_l' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/GNNhpwfLre4?si=RXerYSPWksdw2uLS' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/rv3g2rYvXtg?si=UwkP5axvyEswGxA9' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/UXnlhBTHXvU?si=ymZPwcWoZbkqNz3V' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/650QHfdVyL0?si=JqT-uJOqjE3zjL9S' />
        </div>

        <div style={{ padding:'10px'}}>
          <ReactPlayer url='https://youtu.be/FEIvIhym3KI?si=3msa9QWV4KcxnAwv' />
        </div>
      </div>   
      </div>   
    );
  }
}

export default withTranslation()(Downloads);
