export const TRANSLATIONS_EN = {
  Loading:"Loading",  //Show when waiting for data from Cloud Server 

  //NavBarA Menu and sub Menu items
  Reports:"Reports",
  Setup:"Setup",
  Downloads:"Downloads",
  Logout:"Logout",
  Login:"Login",
  Year:"Year",
  Date_Range:"Date Range",
  Pattern:"Pattern",
  Pump_Service:"Pump Service",
  Pattern_Load_Summary:"Pattern Load Summary",
  TIZ:"TIZ",
  Speeding:"Speeding",
  Import_Pattern:"Import Pattern",
  Notifications:"Notifications",
  People:"People",
  Products:"Products",
  Vehicles:"Vehicles",
  Zones:"Zones",
  Tablets:"Tablets",
  Admin:"Admin",
  Company:"Company",
  Users:"Users",
  Admin_Users: "Setup Users",

  Weight_Pumped_per_Day:"Weight Pumped per Day",
  Recent_Patterns:"Recent Patterns", //Table heading containing List of Recent patterns
  Daily_Summary:"Daily Summary",

  //Report Names
  Pattern_Report:"Pattern Report", //Heading for Pattern Report
  Date_Range_Report:"Date Range Report",
  Time_In_Zone_Report:"Time In Zone(TIZ) Report",

  //Column Headings. Use abbreviations and keep as short as possible
  Vehicle_Issues:"Vehicle Issues", //Possible problems with instalation in truck
  Vehicle:"Vehicle",//Vehicle Name or description
  Description:"Description", //General word for details
  Since:"Since", //Since date
  Days_Ago:"Days Ago",
  Last_Date:"Last Date", //Typical Last date we received data
  Site:"Site", //Site Name
  No_Vehicles:"No Vehicles", //Number of Vehciles
  No_Holes:"No Holes", //Number of Holes`count
  No_Loads:"No Loads", //Number of Loads`count
  No_Events:"No Events", //Number of Events count
  No_Days:"No Days",//Number of days count
  Quantity:"Quantity",//Quantity
  Loaded:"Loaded", //As Loaded
  Survey:"Survey", //As Surveyed
  Surveys:"Surveys", //As Surveyed
  Design:"Design", //As per design
  Approved:"Approved", //Approved by
  Approved_Date:"Approved Date", //Date approval happened
  URL:"URL", //URL Link
  Approved_State:"Approved State", //Is it approved
  Start_Date:"Start Date",
  End_Date:"End Date",
  Select_Site:"Select Site", //In Dropdown when no Site Selected
  Select_Pattern:"Select Pattern", //In Dropdown when no Pattern Selected
  Export_BlastX:"BlastX", //Button name for export data in BlastX format
  CSV_V1:"CSV V1", //Button name for export data in CSV format
  CSV_V2:"CSV V2", //Button name for export data in CSV format
  Export_PDF:"PDF", //Button name for export data in CSV format
  Export_Link:"Copy Link",//Button name to copy URL to clipboard
  Pattern_Approve:"Approve", //Button name to Approve pattern
  Pattern_Unlock:"Unlock",//Button name to Unlock pattern
  Heatmap:"Heatmap", //Button name to taggle map Heatmap layer
  Over_Fill:"OverFill", //Button name to toggle map OverFill layer
  Load_Progress:"LoadProgress",//Button name to toggle map loading Progress layer
  PNG:"PNG", //Button to create PNG image map of pattern
  Summary:"Summary",
  Date:"Date",
  Duration:"Duration",
  Weight:"Loaded",
  Budget:"Budget", //New
  Persentage_Overfill:"% OverFill", //New
  Weight_Truck_Day:"Weight/Truck/Day", //New
  Holes_Truck_Day:"Holes/Truck/Day", //New


  Media:"Media", //Tabe header containg media video and photo files
  Filename:"Filename",
  SizeKB:"Size(KB)", //file size

  //Detail Hole Information
  Id:"Id",
  Pattern_No:"Pattern No",
  Hole_No:"Hole No",
  Depth:"Depth",
  Stemming:"Stemming",
  Diameter:"Diameter",
  Spacing:"Spacing",
  Burden:"Burden",
  DateTime:"Date",
  Driver:"Driver", //Driver or if prefered Operater
  Blaster:"Blaster",
  Loads:"Loads", //Number of Loads at this hole
  Decks:"Decks",//Numver of decks at this hole
  Density:"Density", //Product density
  Product:"Product", //Nmae of product
  Manual:"Manual", //Manual load entry by user
  UnitWeight:"Unit Weight", //Stick weight of manual product
  UnitHeight:"Unit Height", //eg Bag height 
  HoleState:"Hole State", //State of hole eg Wet cracked etc
  Notes:"Notes", //Free text notes for this hole
  Detail_Hole_Information:"Detail Hole Information", //Table Heading for detailed hole information per hole


  //MapInfoWindows
  noLoads:"No Loads", //Number of loads at this hole(Duplicate)
  noDecks:"No Decks", //Number of Decks at this hole(Duplicate)
  noDays:"No Days",//Number of days
  noLoaded:"No Loaded",
  noDesign:"No Design",
  DeckNo:"Deck",//Deck number of this deck
  OK:"OK",//Button Label
  Cancel:"Cancel",//Button Label
  Done:"Done",//Button Label
  Close:"Close",//Button Label
  Angle:"Angle",//Hole angle
  Column_Weight:"Column Weight",
  Column_Height:"Column Height",
  ProductType:"Product Type",
  Decking_Height:"Stemming Height",
  Decking_Type:"Stemming Type",
  Max_Speed:"Max Speed",
  Avg_Speed:"Avg Speed",
  Speed_Limit:"Speed Limit",
  Zone:"Zone",
  Address:"Address", //Phisical address where something happened
  Multi_Hole:"Multi Hole", //Displayed when you editing more than one hole data at the same time. Instead of the hole number
  Selected_loads:"Selected Loads(s)",

  //Year Report
  Year_Report:"Year Report", //Report name
  Select_Year:"Select Year", //Search criteria
  Select_Vehicles:"Select Vehicles", //Search criteria
  Select_Vehicle:"Select Vehicle", //Search criteria
  Select_Product:"Select Product", //Search criteria
  Select_User:"Select User", //Search criteria
  Search:"Search", //Buton label

  Pump_Maintenance_Report:"Pump Maintenance Report",
  History:"History", //Table heading for Maintenance History
  Add_Service_Record:"Add Service Record", //Mantenance Service record
  Service_Date:"Service Date",
  Technician:"Technician", //Who did the work
  Hours_to_Next_Service:"Hours to Next Service",
  Service_Hours:"Service Hours", 
  Hours:"Hours",
  Total_Hours:"Total Hours",
  Pump_Hours:"Pump Hours",
  Last_Service:"Last Service",
  Hours_Since_Service:"Hours Since Service",
  Remaining_Hours:"Remaining Hours",
  Days_to_Service:"Days to Service",
  No_Services:"No Services",
  Avg_Hours_Day:"Avg_Hours_Day",
  Over_Due:"Over Due",

  Monthly_Summary:"Monthly Summary",
  TimeLine:"TimeLine",

  Speeding_Report:"Speeding Report",//Report Heading
  Speeding_Events:"Speeding Events",//Table heading
  Enter:"Enter", //Time you entered a Sites/Zone
  Exit:"Exit", //Time you leaved a Sites/Zone
  Details:"Details",//Description
  Severity:"Severity", //How bad was this event
  Severity_Total:"Severity Total",
  Severity_Day:"Severity Day",
  Active_Days:"Active Days",//Number of days this truck was used in a period

  //Import patterns
  Delete:"Delete",
  Import_New_Pattern:"Import New Pattern",
  Drop_CSV_file_here_or_click_to_Browse:"Drop CSV file here or click to Browse",
  Holes_Loaded:"Holes Loaded",
  Error:"Error",
  UserName:"User Name",

  //Notifictions
  Add_Notification:"Add Notification",
  Setup_Notifications:"Setup Notifications",
  Add:"Add",
  Title:"Title",
  Destination:"Destination",
  Frequency:"Frequency",
  Daily:"Daily",
  Weekdays:"Weekdays",
  Monthly:"Monthly",
  Site_Month:"Site Month",
  Site_Day:"Site Day",
  TIZ_Month:"TIZ Month",
  Hole_Issues:"Hole Issues",
  Hole_Fill:"Hole Fill",
  Maintenance:"Maintenance",

  Setup_People:"Setup People",
  Add_New_Person:"Add New Person",
  Name:"Name", //Name of person
  Enter_Name:"Enter Name",
  Type:"Type", //Driver or Blaster

  Setup_Products:"Setup Products",
  Product_Description:"Product Description",
  Add_New_Product:"Add New Product",

  Vehicle_Setup:"Vehicle Setup",
  Last_Download:"Last Download",
  Hardware_Id:"Hardware Id",
  Pulses_Rev:"Pulses/Rev",
  Rev:"Rev", //Revolution
  Pump_Service_Hours:"Pump Maint Hours",  //Pump Service interval
  OTA_Version:"OTA Version",
  Hose_Reel_Sensor:"Hose Reel Sensor",
  Installed:"Installed",
  Snap_Positions:"Snap Positions",
  Yes:"Yes",
  No:"No",
  Active:"Active",
  Inactive:"Inactive",

  Save:"Save", //Button to Save
  Zone_Edit:"Zone Edit", //Heading for box with Zone edit details
  Loading_Zone:"Loading Zone", //A zone where the truck will load. Also have other zone types like Speed Zone

  //Short and long wedday names
  Sunday:"Sunday",
  Monday:"Monday",
  Tuesday:"Tuesday",
  Wednesday:"Wednesday",
  Thursday:"Thursday",
  Friday:"Friday",
  Saturday:"Saturday",
  Sun:"Son",
  Mon:"Mon",
  Tue:"Tue",
  Wed:"Wed",
  Thur:"Thur",
  Fri:"Fri",
  Sat:"Sat",

  //Short Month Names  
  Jan:"Jan",
  Feb:"Feb",
  Mar:"Mar",
  Apr:"Apr",
  May:"May",
  Jun:"Jun",
  Jul:"Jul",
  Aug:"Aug",
  Sep:"Sep",
  Oct:"Oct",
  Nov:"Nov",
  Dec:"Dec",

  //Hole States
  Wet:"Wet",
  Decked:"Decked",
  ReDrill:"ReDrill",
  Cracked:"Cracked",
  TopLoaded:"TopLoaded",
  Collapsed:"Collapsed",
  CleanOut:"CleanOut",
  OverBurden:"OverBurden",
  Tacked:"Tacked",
  OverLoaded:"OverLoaded",
  UnderLoaded:"UnderLoaded",
  Hot:"Hot",

  //General Text
  Presentations: "Presentations",
  IBX_Install_Manual: "IBX Installation Guide",
  Reach_Setup_Manual: "EMLID Reach RS Integration Guide",
  Android_Applications:"Android Applications",
  Android_Install_Application_Version:"Android Install Application Version",
  Android_Application_Version:"Android iBlastX Application Version",
  File_Upload_Failed:"File Upload Failed!",
  File_Upload_Already_in_Progress:"File Upload Already in Progress!",
  Drag_and_drop_some_files_here_or_click_to_select_files:"Drag and drop some files here or, click to select files",
  Bad_Hose_Reel_Sensor:"Bad Hose Reel Sensor",
  No_Communication:"No Communication",

  Survey_None:"None",
  Survey_Depth_OK:"Depth OK",
  Survey_Depth_Over:"Depth +",
  Survey_Depth_Under:"Depth -",
  Survey_Loaded:"Loaded",
  Survey_Stemming_OK:"Stemming OK",
  Survey_Stemming_Over:"Stemming +",
  Survey_Stemming_Under:"Stemming -",

  Depth_Tol:"Depth Tolerance(%)",
  Stemming_Tol:"Stemming Tolerance(%)",
  Add_Load:"Add Load",
  Add_Design:"Add Design",

  Edit_Design:"Edit Design",

  SurveyLatest   :"Latest",
  SurveyDepth    :"Depth",
  SurveyLoaded   :"Loaded",
  SurveyStemming :"Stemming",
  SurveyWaterDepth :"Water Depth",
  SurveyTemperature :"Temperature",

  SurveyPostDriling :"Post Drilling",
  SurveyPreLoading  :"Pre Loading",
  SurveyPostLoading :"Post Loading",
  SurveyPreStemming :"Pre Stemming",

  Survey_Loading     : "Loading",
  Survey_PostDrill   : "Post Drill",
  Survey_PreLoading  : "Pre Loading",
  Survey_PostLoading : "Post Loading",
  Survey_PreStemming : "Pre Stemming",

  OverFillDesign :"Design",
  OverFillSurvey :"Survey",
  OverFillIdeal  :"Ideal",

  Unkown_Holes : "Unkown Holes",
  No_Data_Found : "No Data Found",

  Setup_Tablets : "Tablet Setup",
  Connected     : "Connected",
  Registered    : "Registered",
  Version       : "Version",
  Mode          : "Mode",

  Accessories : "Accessories",
  Add_New_Accessory : "Add New Accessory",
  Setup_Accessories : "Setup Accessories",
  Accessory_Summary : "Accessory Summary",
  Accessory_Description : "Accessory Description",
  Booster : "Booster",
  Detonator : "Detonator",
  Other : "Other",
  Barcode : "Barcode",
  ExportId : "Export Id",

  Total_Weight : "Total Weight",
  Design_Weight : "Design Weight",
  Start_Depth : "Start Depth",

  User_Name : "User Name",
  Email : "email",
  Timezone : "Timezone",
  Language : "Language",
  Phone_No : "Phone No",
  Permissions : "Permissions",
  Units : "Units",
  Last_Login : "Last Login",

  Add_New_User : "Add New User",
  New_User_Name : "User Name",

  GPS_Position : "GPS Position",
  Image : "Image",


  Pattern_Load_Summary_Report : "Pattern Load Summary Report",
  DashBoard : "Dashboard",
  BlastReport_PDF : "Blast Report PDF",
  DrillReport_PDF : "Drill Report PDF",
  Phase : "QAQC Phase",
  Start : "Start",
  StartDepth : "Start Depth",
  ColumnHeight : "Column",
  StemmingHeight : "Stemming",
  


};

