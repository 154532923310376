import React, { Component } from 'react';
import { Route} from 'react-router-dom';
import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
  
import Amplify from 'aws-amplify'
import config from './aws-exports'
import { Auth } from 'aws-amplify'

import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import Background from './loginBg.jpg'
import YouTube from './youtube.png'

import MyNavbar from './components/NavBar';
import Home from './components/Home';
import Downloads from './components/Downloads';
import SetupVehicles from './components/SetupVehicles';
import SetupTablets from './components/SetupTablets';
import SetupPeople from './components/SetupPeople';
import SetupZones from './components/SetupZones';
import SetupProduct from './components/SetupProduct';
import SetupAccessories from './components/SetupAccessories';
import SetupNotifications from './components/SetupNotifications';
import SetupImportPattern from './components/SetupImportPattern';
import AdminCompanies from './components/AdminCompanies';
import AdminUsers from './components/AdminUsers';
import Contact from './components/Contact';
import Report1 from './components/Report.1';
import Report2 from './components/Report.2';
import Report4 from './components/Report.4';
import Report5 from './components/Report.5';
import Report6 from './components/Report.6';
import Report7 from './components/Report.7';
import ReportPatternLoadSummary from './components/Report.PatternLoadSummary';
import ReportUnkownHoles from './components/ReportUnkownHoles';
import i18n from './translations/i18n';
import { Hub } from 'aws-amplify';

import './App.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

import Start from './components/Start';


//Amplify.configure(config)
Amplify.configure({
    Auth: {
        identityPoolId: "us-east-1:3f862eb5-7606-45dc-af86-fcc849557be5",
        region: "us-east-1", 
        userPoolId: "us-east-1_ldjQ295Td", 
        userPoolWebClientId: "1016lbn3dno5j8eik0c2ok4kmr", 
    },
    Storage: {
        AWSS3: {
            bucket: 'ifleetxmediabucket01', //REQUIRED -  Amazon S3 bucket
            region: "us-east-1", //OPTIONAL -  Amazon service region
        }
    }
});

class App extends Component {
  constructor(props) {

    super(props);
    this.state = {
      userDetails : null,
      userName    : null,
      userId      : null,
      loggedIn    : 0,
      info        : 0,
    };
  }

  async componentDidMount() {
   Auth.currentAuthenticatedUser()
      .then(session => {
        console.log('Logged In:', session.username);
        this.setState({ userName:session.username, userId:session.attributes.sub, loggedIn:true}); 
        this.getUserDetails(session.username, session.attributes.sub, session.attributes.email, session.attributes.phone_number);
        })
      .catch(() => {
        console.log('Not logged in');
        this.setState({loggedIn:0, userDetails:false});
        });

    //this.user = await Auth.currentAuthenticatedUser();
    //console.log('username:', this.user);
    //this.setState({ userName:this.user.username, userId:this.user.attributes.sub}); 
    //this.getUserDetails(this.user.username, this.user.attributes.sub, this.user.attributes.email, this.user.attributes.phone_number);
  }


  getUserDetails = (userName, userId, email, phoneNo) => {
    fetch(process.env.REACT_APP_HOST_URL+'registerUserGetDS.php',
    {
       method: 'POST',
       body: JSON.stringify(
       {
           userName  : userName,
           userId    : userId,
           email     : email,
           phoneNo   : phoneNo
       })
    })
    .then(response => response.json())
    .then(data =>{
                   //var userLang = navigator.language || navigator.userLanguage;
                   //data.language = userLang;
                   i18n.changeLanguage(data.language);
                   this.setState({userDetails:data});
                 })
    .catch(error => this.setState({ userDetails:null }));
  }


  updateUserDetails = (language) => {
    console.log('App:upDateUserDetails:'+language );

    fetch(process.env.REACT_APP_HOST_URL+'registerUserUpdateDS.php',
    {
       method: 'POST',
       body: JSON.stringify(
       {
           userId : this.state.userDetails.userId,
           language : language 
       })
    })
    .then(response => response.json())
    .then(data =>{
                   this.getUserDetails(this.state.userName, this.state.userId, '','');
                 })
    .catch(error => this.setState({ error }));
  }


  onClickLogin = (event) => {
    console.log('App:onClickLogin:',event );
    this.setState({loggedIn:1});
  }

  render() {
   Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
          console.log('user signed in',data);
          if (this.state.userId !== data.payload.data.attributes.sub) {
            this.getUserDetails(data.payload.data.username, data.payload.data.attributes.sub, data.payload.data.attributes.email, data.payload.data.attributes.phone_number);
            this.setState({ userName:data.payload.data.username, userId:data.payload.data.attributes.sub, loggedIn:2});
          }
          break;
      case 'signUp':
          console.log('user signed up');
          this.setState({loggedIn:0, userDetails:null, userId:null});
          break;
      case 'signOut':
          console.log('user signed out',data);
          this.setState({loggedIn:0, userDetails:null, userId:null});
          break;
      case 'signIn_failure':
          console.log('user sign in failed');
          this.setState({loggedIn:1, userDetails:null, userId:null});
          break;
      case 'configured':
          console.log('the Auth module is configured');
    }
  });
    console.log ("App:history", this.props.history);

    return (
      <HashRouter>
    <div>
    { (this.state.loggedIn === 0) ?
        <div> 
           <Route path="/info" component={Downloads}/>
           <Route exact path="/" 
                  render={(props) => <Start onClickLogin={this.onClickLogin} 
                                            {...props} /> }
           />
           <Route exact path="/home" 
                  render={(props) => <Start onClickLogin={this.onClickLogin} 
                                            {...props} /> }
           />
        </div>
    : (this.state.loggedIn === 1) ?
      <div style={{backgroundColor:'black' }}>
        <AmplifyAuthenticator>
          <AmplifySignIn
            headerText="iBlastX"/>
        </AmplifyAuthenticator>
      </div>
    : (!this.state.userDetails)? <p>...</p>:
        <div>            
        <MyNavbar bg="dark" variant="dark" userName={this.state.userName} userDetails={this.state.userDetails} updateUserDetails={this.updateUserDetails}/>
          <div>
            <Route path="/downloads" component={Downloads}/>
            <Route path="/contact" component={Contact}/>
            <Route
                exact path='/'
                render={(props) => <Home {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/home'
                render={(props) => <Home {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupVehicles'
                render={(props) => <SetupVehicles {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupNotifications'
                render={(props) => <SetupNotifications {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupPeople'
                render={(props) => <SetupPeople {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupZones'
                render={(props) => <SetupZones {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupProduct'
                render={(props) => <SetupProduct {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupAccessories'
                render={(props) => <SetupAccessories {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupImportPattern'
                render={(props) => <SetupImportPattern {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/adminCompanies'
                render={(props) => <AdminCompanies {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupTablets'
                render={(props) => <SetupTablets {...props}
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/setupUsers'
                render={(props) => <AdminUsers {...props}
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/adminUsers'
                render={(props) => <AdminUsers {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/report1'
                render={(props) => <Report1 {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/report2'
                render={(props) => <Report2 {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/report4'
                render={(props) => <Report4 {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/report5'
                render={(props) => <Report5 {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/report6'
                render={(props) => <Report6 {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/report7'
                render={(props) => <Report7 {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/ReportUnkownHoles'
                render={(props) => <ReportUnkownHoles {...props} 
                                      userDetails={this.state.userDetails}/>}
            />
            <Route
                path='/ReportPatternLoadSummary'
                render={(props) => <ReportPatternLoadSummary {...props}
                                      userDetails={this.state.userDetails}/>}
            />
          </div>      
        </div>
    }</div>
      </HashRouter>
    );
  }
}

export default App;
//export default App, {includeGreetings:false};

